import React from 'react';
const PageTitle = ({ title }) => {
    
    const wordsTitle = title.split(' ');
    // S'il y a plus de 3 mots, couper 
    const firstLine = wordsTitle.length > 2 ? wordsTitle.slice(0, 2).join(' ') : ''
    const secondLine = wordsTitle.length > 2 ? wordsTitle.slice(2, wordsTitle.length).join(' ') : wordsTitle.join(' ')

    return(
        <div>
            {wordsTitle.length > 2 && <h1 className="page-title">{firstLine}</h1>}
            {wordsTitle.length > 2 && <h1 id="second-line" className="page-title">{secondLine}</h1>}
            {wordsTitle.length <= 2 && <h1 className='page-title'>{secondLine}</h1>}
        </div>
        
        
    )
}

export default PageTitle;