import React from 'react';
import { graphql } from 'gatsby';
// components
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import Seo from '../components/SEO';
import Gallery from '@browniebroke/gatsby-image-gallery'

const Galerie = ( { data } ) => {

    let galerie = data.markdownRemark.frontmatter.galerie
    let images = galerie.map((img) => img.photo.childImageSharp)
    console.log(images)


    return(
        <Layout>
            <Seo title="Galerie" />
            <div className="main-container">
                <PageTitle title="Galerie" />
                    <Gallery images={images} />
            </div>
 
        </Layout>
    )
}

export default Galerie;

export const pageQuery = graphql`
{
    markdownRemark(fileAbsolutePath: {regex: "/galerie/"}) {
      frontmatter {
              galerie {
                  photo {
                    childImageSharp {
                      thumb: gatsbyImageData(
                            placeholder: BLURRED
                            width: 270
                            height: 270
                        )
                        full: gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
         }
    }
  }
`;